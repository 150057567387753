import React, { useEffect, useState } from 'react';
import { useFetchMe } from 'src/hooks/react-query/userQueries';
import { useFetchMyPlan } from 'src/hooks/react-query/pricingQueries';
import LoadingProfile from 'src/pages/Profile/LoadingProfile';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';
import VerticalDivideWindow from 'src/groups/VerticalDivideWindow';
import FlexAlign from 'src/groups/FlexAlign';
import Gradient from 'src/groups/Gradient';
import BorderBox from 'src/groups/BorderBox';
import Button from 'src/components/button/Button';
import leaf1 from 'src/assets/images/leaf1.svg';
import leaf2 from 'src/assets/images/leaf2.svg';
import edit from 'src/assets/icons/edit.svg';
import Quicklink from 'src/components/quicklink/Quicklink';
import Input from 'src/components/forms/input/Input';
import { useFormik } from 'formik';
import Spinner from 'src/components/Spinner';

const Profile: React.FC = () => {
    const me = useFetchMe();
    const myPlan = useFetchMyPlan();
    const navigate = useNavigate();
    const [showForm, setShowForm] = useState(false);
    const formik = useFormik({
        initialValues: {
            name: '',
            email: ''
        },
        onSubmit: (values) => {
            navigate('/');
        }
    });
    useEffect(() => {
        if (me.isSuccess) {
            formik.setValues({ name: me.data.first_name, email: me.data.email });
        }
    }, [me.isSuccess]);
    const icon = (
        <span className="inline-block w-[1.175rem] h-[1.175rem] rounded-[50%] bg-grayscale-30 flex justify-center items-center">
            <svg
                className="w-[.613rem] h-[.425rem] text-grayscale-70"
                viewBox="3.16 0 0.9 5.2"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
            >
                <g>
                    <title>Done</title>
                    <path
                        stroke="currentColor"
                        fill="none"
                        id="done"
                        strokeLinejoin="round"
                        strokeLinecap="round"
                        strokeWidth="1.2"
                        d="m0.6,2.6l2,2l4,-4"
                    />
                </g>
            </svg>
        </span>
    );
    if (me.isLoading) {
        return (
            <div className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] scale-[3]">
                <Spinner />
            </div>
        );
    }
    if (me.isSuccess) {
        return (
            <VerticalDivideWindow classnames="text-grayscale-00" isLoggedIn={true}>
                <div className="flex-[6] pt-[120px] pb-[60px]">
                    <div className="flex flex-col justify-around gap-3 h-full pl-[70px] pr-[140px] mdlg:pr-[70px]">
                        <div className="max-h-[450px] h-full">
                            <div className="flex flex-col gap-4 justify-between w-full h-full">
                                <h2>
                                    Cześć{me.data.first_name ? ' ' + me.data.first_name : ''},
                                    witamy na Twoim koncie!
                                </h2>
                                <BorderBox classnames="relative">
                                    <form className="flex flex-col gap-3 justify-between">
                                        <div>
                                            <div className="flex flex-row justify-between">
                                                <span className="font-bold-16">TWOJE DANE</span>
                                                {!showForm && (
                                                    <Quicklink
                                                        onClick={() => {
                                                            // console.log(formikValues);
                                                            setShowForm((el) => !el);
                                                        }}
                                                        size={'large'}
                                                        to={'#'}
                                                        classnames="-translate-y-2"
                                                        icon={{
                                                            src: edit,
                                                            side: 'right',
                                                            classnames: 'ml-1 p-[3px]'
                                                        }}
                                                    >
                                                        Edytuj
                                                    </Quicklink>
                                                )}
                                                {showForm && (
                                                    <div className="flex flex-row gap-[16px]">
                                                        <Button
                                                            size={'medium'}
                                                            styleType={'secondary'}
                                                            type={'button'}
                                                            onClick={() => setShowForm((el) => !el)}
                                                        >
                                                            ANULUJ
                                                        </Button>
                                                        <Button
                                                            size={'medium'}
                                                            styleType={'primary'}
                                                            type={'submit'}
                                                        >
                                                            ZAPISZ
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="w-full h-[1px] border-b-[1px] border-grayscale-40"></div>
                                        <div className="flex flex-col gap-3 justify-between">
                                            <div className="grid grid-cols-4 gap-[12px]">
                                                <span className="font-light-14">Imię:</span>
                                                {showForm && (
                                                    <Input
                                                        placeholder={''}
                                                        name={'name'}
                                                        handleChange={formik.handleChange}
                                                        type={'text'}
                                                        id={'name'}
                                                        classnames="col-span-3"
                                                        isError={false}
                                                        errorMessage={''}
                                                        value={formik.values.name}
                                                    />
                                                )}
                                                {!showForm && (
                                                    <span className="col-span-3 font-semibold-14">
                                                        {me.data.first_name}
                                                    </span>
                                                )}
                                                <span className="font-light-14">Adres e-mail:</span>
                                                {showForm && (
                                                    <Input
                                                        placeholder={''}
                                                        name={'email'}
                                                        handleChange={formik.handleChange}
                                                        type={'text'}
                                                        id={'email'}
                                                        classnames="col-span-3"
                                                        isError={false}
                                                        errorMessage={''}
                                                        value={formik.values.email}
                                                    />
                                                )}
                                                {!showForm && (
                                                    <span className="col-span-3 font-semibold-14">
                                                        {me.data.email}
                                                    </span>
                                                )}
                                            </div>
                                            <p className="font-regular-12 text-grayscale-20">
                                                Twoje hasło zostało zmienione 7 dni temu.
                                            </p>
                                            <Quicklink size={'medium'} to={'#'}>
                                                Zmień hasło
                                            </Quicklink>
                                        </div>
                                    </form>
                                </BorderBox>
                                <BorderBox classnames="flex flex-col gap-3 justify-between">
                                    <p className="font-bold-16 text-grayscale-00">
                                        ROZLICZENIA I PŁATNOŚCI
                                    </p>
                                    <div className="w-full h-[1px] border-b-[1px] border-grayscale-40"></div>
                                    <p>
                                        <span className="font-light-14 text-grayscale-00">
                                            Subskrypcja miesięczna:&nbsp;&nbsp;&nbsp;
                                        </span>
                                        <span className="font-semibold-14 text-grayscale-00">
                                            Darmowa
                                        </span>
                                    </p>
                                </BorderBox>
                            </div>
                        </div>
                        <div className="w-full text-grayscale-40">
                            <Quicklink size={'medium'} to={'#'}>
                                Usuń konto
                            </Quicklink>
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                            <Quicklink
                                size={'medium'}
                                to={'https://goodwrite.pl/regulamin/'}
                                target="_blank"
                            >
                                Regulamin
                            </Quicklink>
                            &nbsp;&nbsp;|&nbsp;&nbsp;
                            <Quicklink
                                size={'medium'}
                                to={'https://goodwrite.pl/polityka-prywatnosci/'}
                                target="_blank"
                            >
                                Polityka prywatności
                            </Quicklink>
                        </div>
                    </div>
                </div>
                <Gradient classnames="flex-[5] block mdlg:hidden">
                    <FlexAlign classnames="py-[120px]">
                        <div className="max-w-[500px] h-full relative">
                            <img src={leaf1} className="absolute left-[-130px] top-[30%]" />
                            <img src={leaf2} className="absolute left-[-180px] top-[57%]" />
                            <BorderBox classnames="relative h-full flex flex-col justify-between gap-5 scroll">
                                <div className="flex flex-col max-h-[350px] gap-4 h-full">
                                    <div className="flex flex-col gap-2">
                                        <div className="flex flex-row justify-between">
                                            <h4>Twój plan</h4>
                                            <div className="bg-light1 px-[12px] py-[4px] font-bold-14 text-default rounded-[20px]">
                                                PODSTAWOWY
                                            </div>
                                        </div>
                                        <p className="font-regular-14">
                                            Idealny dla tych, którzy redagują teksty nieoficjalne
                                            (e-maile lub komentarze i wiadomości w mediach
                                            społecznościowych i portalach internetowych).
                                        </p>
                                        <div className="font-regular-14 text-grayscale-10">
                                            <h3 className="inline-block text-grayscale-00">
                                                0 zł&nbsp;
                                            </h3>
                                            <span className="align-top">&nbsp;/ miesiąc</span>
                                        </div>
                                        <p className="font-regular-14 text-grayscale-20">
                                            Za darmo na zawsze
                                        </p>
                                    </div>
                                    <div className="block w-full h-[1px] border-b-[1px] border-grayscale-40"></div>
                                    <div className="flex flex-col h-full justify-between">
                                        <div className="flex flex-row gap-2">
                                            <span>{icon}</span>
                                            <span className="inline font-semibold-14 text-grayscale-00">
                                                Zaawansowana korekta językowa (w zakresie pisowni i
                                                gramatyki)
                                            </span>
                                        </div>
                                        <div className="flex flex-row gap-2">
                                            <span>{icon}</span>
                                            <span className="font-semibold-14 text-grayscale-00">
                                                Inteligentnie rozpoznawanie błędów wynikających z
                                                szybkiego pisania
                                            </span>
                                        </div>
                                        <div className="flex flex-row gap-2">
                                            <span>{icon}</span>
                                            <span className="font-semibold-14 text-grayscale-00">
                                                Dodatek do przeglądarek Google Chrome oraz Mozilla
                                                Firefox
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <Button
                                    classnames="inline"
                                    size={'large'}
                                    styleType={'primary'}
                                    type={'button'}
                                    onClick={() => navigate('/plan')}
                                >
                                    ZARZĄDZAJ PLANEM
                                </Button>
                            </BorderBox>
                        </div>
                    </FlexAlign>
                </Gradient>
            </VerticalDivideWindow>
        );
    }
    return <></>;
};

export default Profile;
