import { ReactNode } from 'react';
import cx from 'classnames';
interface Props {
    children?: ReactNode;
    classnames?: string;
}

const FlexAlign: React.FC<Props> = ({ children, classnames }) => {
    return (
        <div
            className={cx([
                classnames,
                'h-full w-full flex justify-center content-center flex-wrap'
            ])}
        >
            {children}
        </div>
    );
};
export default FlexAlign;
