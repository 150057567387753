import cx from 'classnames';
import { ReactNode, useEffect, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
interface Props {
    children: ReactNode;
    classnames?: string;
    size: 'small' | 'medium' | 'large';
    icon?: {
        src: string;
        side: 'left' | 'right';
        classnames?: string;
    };
    onClick?: any;
    target?: string;
    disabled?: boolean;
    to: string;
}

const Quicklink: React.FC<Props> = ({
    children,
    classnames,
    size,
    icon,
    onClick,
    disabled,
    to,
    ...rest
}) => {
    const [Icon, setIcon] = useState('');
    if (icon) {
        useEffect(() => {
            fetch(icon.src)
                .then((response) => response.text())
                .then((svgContent) => {
                    setIcon(svgContent);
                });
        });
    }

    const arrowEl = (
        <span className={icon && icon.classnames} dangerouslySetInnerHTML={{ __html: Icon }}></span>
    );
    return (
        <Link
            {...rest}
            to={to}
            onClick={onClick}
            className={cx([
                'quicklink',
                classnames,
                `font-semibold-${size === 'small' ? '12' : size === 'medium' ? '14' : '16'}`,
                disabled ? 'disabled' : ''
            ])}
        >
            {icon && icon.side === 'left' && arrowEl}
            {children}
            {icon && icon.side === 'right' && arrowEl}
        </Link>
    );
};

export default Quicklink;
