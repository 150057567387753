import { ReactNode } from 'react';
import cx from 'classnames';
interface Props {
    children?: ReactNode;
    classnames?: string;
}

const BorderBox: React.FC<Props> = ({ children, classnames }) => {
    return (
        <div
            className={cx([
                classnames,
                'py-[26px] px-[32px] flex flex-col bg-grayscale-70 border-[1px] rounded-[4px] border-grayscale-40'
            ])}
        >
            {children}
        </div>
    );
};
export default BorderBox;
