import { useFormik } from 'formik';
import Input from 'src/components/forms/input/Input';
import icon from 'src/assets/icons/letter.svg';
import arrow from 'src/assets/icons/chevron-down.svg';
import Checkbox from 'src/components/forms/selection/Checkbox';
import Switch from 'src/components/forms/selection/Switch';
import Quicklink from 'src/components/quicklink/Quicklink';
import Button from 'src/components/button/Button';
import SegmentedControl from 'src/components/forms/selection/SegmentedControl';
import Popover from 'src/components/popover/Popover';

const Test = () => {
    const formik = useFormik({
        initialValues: {
            text: 'Lorem ipsum',
            test1: '',
            test2: '',
            icon: '',
            checkbox: false,
            switch: false,
            segment: false
        },
        onSubmit: (values) => {
            alert(JSON.stringify(values, null, 2));
        }
    });
    const Icon = <img src={icon} />;
    return (
        <div key={1} style={{ padding: '0 0 200px 0' }}>
            <Input
                placeholder={'test'}
                classnames="m-5"
                name={'text'}
                handleChange={formik.handleChange}
                type={'test'}
                id={'text'}
                isError={false}
                errorMessage={''}
                value={formik.values['text']}
            />
            {['light', 'regular', 'semibold', 'bold'].map((weight) =>
                [10, 12, 14, 16].map((size) => (
                    <p key={weight + size} className={`font-${weight}-${size} m-0`}>
                        {formik.values['text']}
                    </p>
                ))
            )}
            {['h1', 'h2', 'h3', 'h4'].map((h) => (
                <div
                    className="text-center"
                    key={h}
                    dangerouslySetInnerHTML={{ __html: `<${h}>${formik.values['text']}</${h}?>` }}
                ></div>
            ))}
            <Input
                placeholder={'test'}
                classnames="m-5"
                name={'test2'}
                handleChange={formik.handleChange}
                type={'test'}
                id={'test2'}
                isError={true}
                errorMessage={'test'}
                value={formik.values['test2']}
            />
            <Input
                placeholder={'test'}
                classnames="m-5"
                name={'icon'}
                handleChange={formik.handleChange}
                type={'test'}
                id={'icon1'}
                isError={false}
                errorMessage={''}
                value={formik.values['icon']}
                iconLeft={Icon}
            />
            <Input
                placeholder={'test'}
                classnames="m-5"
                name={'icon1'}
                handleChange={formik.handleChange}
                type={'test'}
                id={'icon2'}
                isError={false}
                errorMessage={''}
                value={formik.values['icon']}
                iconRight={Icon}
            />
            <Input
                placeholder={'test'}
                classnames="m-5"
                name={'icon'}
                handleChange={formik.handleChange}
                type={'test'}
                id={'icon3'}
                isError={false}
                errorMessage={''}
                value={formik.values['icon']}
                iconLeft={Icon}
                iconRight={Icon}
            />
            <Input
                placeholder={'test'}
                classnames="m-5"
                name={'icon'}
                handleChange={formik.handleChange}
                type={'test'}
                id={'icon3'}
                isError={true}
                errorMessage={
                    'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Iure, explicabo.'
                }
                value={formik.values['icon']}
                iconLeft={Icon}
                iconRight={Icon}
            />
            <Input
                placeholder={'test'}
                classnames="m-5"
                name={'icon'}
                handleChange={formik.handleChange}
                type={'test'}
                id={'icon3'}
                isError={false}
                errorMessage={'Additional text'}
                value={formik.values['icon']}
            />
            <Checkbox
                name={'checkbox'}
                id={'checkbox'}
                type={'checkbox'}
                onChange={formik.handleChange}
                checked={formik.values.checkbox}
            />
            <Checkbox
                name={'checkbox'}
                id={'checkbox'}
                type={'checkbox'}
                onChange={formik.handleChange}
                checked={!formik.values.checkbox}
            />
            <Switch
                name={'switch'}
                id={'switch'}
                type={'checkbox'}
                onChange={formik.handleChange}
                checked={formik.values.switch}
            />
            <Switch
                name={'switch'}
                id={'switch'}
                type={'checkbox'}
                onChange={formik.handleChange}
                checked={!formik.values.switch}
            />
            {['small', 'medium', 'large'].map((size) => {
                const attr = size as 'small' | 'medium' | 'large';
                return (
                    <p key={size}>
                        <Quicklink to="#text" size={attr}>
                            Quicklink
                        </Quicklink>
                        &nbsp;
                        <Quicklink
                            to="https://www.youtube.com/watch?v=dQw4w9WgXcQ&pp=ygULcmljayBhc3RsZXk%3D"
                            size={attr}
                            icon={{ src: arrow, side: 'left' }}
                        >
                            Quicklink
                        </Quicklink>
                        &nbsp;
                        <Quicklink
                            to="https://www.youtube.com/watch?v=dQw4w9WgXcQ&pp=ygULcmljayBhc3RsZXk%3D"
                            size={attr}
                            icon={{ src: arrow, side: 'right' }}
                            target="_blank"
                        >
                            Quicklink
                        </Quicklink>
                    </p>
                );
            })}
            {['primary', 'secondary', 'tertiary'].map((styleType) => {
                const styleAttr = styleType as 'primary' | 'secondary' | 'tertiary';
                return ['large', 'medium', 'small'].map((size) => {
                    const sizeAttr = size as 'large' | 'medium' | 'small';
                    return (
                        <div
                            key={styleType + size}
                            style={{ display: 'flex', gap: '10px', margin: '10px 0' }}
                        >
                            <Button type="submit" size={sizeAttr} styleType={styleAttr}>
                                {`${(styleAttr + ' ' + sizeAttr).toUpperCase()}`}
                            </Button>
                            <Button
                                type="submit"
                                size={sizeAttr}
                                styleType={styleAttr}
                                arrow="left"
                            >
                                {`${(styleAttr + ' ' + sizeAttr).toUpperCase()}`}
                            </Button>
                            <Button
                                type="submit"
                                size={sizeAttr}
                                styleType={styleAttr}
                                arrow="right"
                            >
                                {`${(styleAttr + ' ' + sizeAttr).toUpperCase()}`}
                            </Button>
                            <Button
                                type="submit"
                                size={sizeAttr}
                                styleType={styleAttr}
                                arrow="left"
                            ></Button>
                            <Button
                                type="submit"
                                size={sizeAttr}
                                styleType={styleAttr}
                                arrow="right"
                            ></Button>
                        </div>
                    );
                });
            })}

            <SegmentedControl
                name={'segment'}
                id={'segment'}
                type={'checkbox'}
                onChange={formik.handleChange}
                checked={formik.values['segment']}
                falseText={`Left`}
                trueText={'Right'}
            ></SegmentedControl>
            <SegmentedControl
                name={'segment'}
                id={'segment'}
                type={'checkbox'}
                onChange={formik.handleChange}
                checked={!formik.values['segment']}
                falseText={`Left`}
                trueText={'Right'}
            ></SegmentedControl>

            <Popover>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quasi voluptatem
                possimus nostrum magni porro aspernatur nesciunt! Quia, esse quae?
            </Popover>
        </div>
    );
};

export default Test;
