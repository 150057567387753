import React, { ReactNode } from 'react';
import { FormikHandlers } from 'formik';
import cx from 'classnames';

interface Props {
    labelClassName?: string;
    placeholder: string;
    name: string;
    handleChange: FormikHandlers['handleChange'];
    type: string;
    id: string;
    classnames?: string;
    isError: boolean;
    errorMessage: string | undefined;
    value: string;
    iconLeft?: ReactNode;
    iconRight?: ReactNode;
    disabled?: boolean;
}

const Input: React.FC<Props> = ({
    name,
    id,
    type,
    placeholder,
    handleChange,
    classnames,
    isError,
    errorMessage,
    value,
    iconLeft,
    iconRight,
    disabled
}) => {
    return (
        <div className={cx(['input', errorMessage ? 'showed-message' : '', classnames])}>
            {iconLeft}
            <input
                name={name}
                id={id}
                type={type}
                placeholder={placeholder}
                className={cx([isError ? 'valid-error' : '', iconRight ? 'icon-right' : ''])}
                value={value}
                autoComplete={name}
                onChange={handleChange}
                disabled={disabled}
            />
            {iconRight}
            {errorMessage && (
                <small className={cx(['additionalText', isError ? 'error' : ''])}>
                    {errorMessage}
                </small>
            )}
        </div>
    );
};

export default Input;
