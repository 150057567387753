import { ReactNode, useEffect, useState } from 'react';
import cx from 'classnames';
import icon from 'src/assets/icons/arrow.svg';

interface Props {
    children?: ReactNode;
    arrow?: 'left' | 'right';
    classnames?: string;
    size: 'small' | 'medium' | 'large';
    styleType: 'primary' | 'secondary' | 'tertiary';
    type: 'button' | 'submit' | 'reset';
    disabled?: boolean;
    onClick?: any;
}

const Button: React.FC<Props> = ({
    arrow,
    classnames,
    children,
    type,
    size,
    styleType,
    disabled,
    onClick
}) => {
    const [Icon, setIcon] = useState('');
    useEffect(() => {
        fetch(icon)
            .then((response) => response.text())
            .then((svgContent) => {
                setIcon(svgContent);
            });
    });
    const arrowL = <span dangerouslySetInnerHTML={{ __html: Icon }}></span>;
    const arrowR = (
        <span
            style={{ transform: 'rotate(180deg)' }}
            dangerouslySetInnerHTML={{ __html: Icon }}
        ></span>
    );
    return (
        <button
            type={type}
            className={cx([
                classnames,
                styleType,
                `font-${
                    size === 'small' ? 'semibold-12' : `bold-${size === 'medium' ? '14' : '16'}`
                }`
            ])}
            onClick={onClick}
            disabled={disabled}
        >
            {arrow === 'left' && arrowL}
            {children}
            {arrow === 'right' && arrowR}
        </button>
    );
};

export default Button;
