import axios, { AxiosError, Canceler } from 'axios';
import { authApi } from 'src/api/api';
import { LOCAL_STORAGE } from 'src/consts/consts';
import * as dotenv from 'dotenv';

// dotenv.config();

export let cancel: Canceler;

const client = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

client.interceptors.response.use(
    (response) => {
        return response;
    },
    async (err) => {
        const originalConfig = err.config;
        if (err.response) {
            const token = localStorage.getItem(LOCAL_STORAGE.token);
            if (err.response.status === 401 && token && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    const rs = await authApi.refreshToken();
                    const { access_token } = rs.data;
                    localStorage.setItem(LOCAL_STORAGE.token, access_token);
                    return client(originalConfig);
                } catch (err) {
                    localStorage.removeItem(LOCAL_STORAGE.token);
                    if ((err as AxiosError).response && (err as AxiosError).response?.data) {
                        return Promise.reject((err as AxiosError).response?.data);
                    }
                    return Promise.reject(err);
                }
            }
        }
        return Promise.reject(err);
    }
);

client.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('GW_TOKEN');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default client;
