import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import Button from './button/Button';

const CookiesManager: React.FC = () => {
    const [cookies, setCookie] = useCookies(['gw-panel-accept-cookie-usage']);
    const [isOpen, setIsOpen] = useState(cookies['gw-panel-accept-cookie-usage'] !== true);

    const handleAgreement = () => {
        setIsOpen(false);
        setCookie('gw-panel-accept-cookie-usage', true);
    };

    if (isOpen) {
        return (
            <section className="w-full p-5 bottom-0 left-0 bg-grayscale-50 fixed z-50">
                <div className="flex flex-row xxs:flex-col gap-4">
                    <p>
                        Ta strona używa plików cookies w celu korzystania z narzędzi analitycznych,
                        marketingowych i społecznościowych. Jeżeli twoje ustawienia nie blokują
                        cookies, wyrażasz zgodę na ich wykorzystywanie. Możesz określić zasady
                        korzystania z cookies w swojej przeglądarce
                    </p>
                    <div className="flex flex-row md:flex-col gap-4">
                        {' '}
                        <Button
                            classnames="m-auto inline w-full"
                            size={'medium'}
                            styleType={'primary'}
                            type={'button'}
                            onClick={handleAgreement}
                        >
                            Rozumiem
                        </Button>
                        <Button
                            classnames="m-auto inline w-full whitespace-nowrap"
                            size={'medium'}
                            styleType={'secondary'}
                            type={'button'}
                            onClick={() =>
                                window.open('https://goodwrite.pl/polityka-prywatnosci/ ')
                            }
                        >
                            Polityka prywatności
                        </Button>
                    </div>
                </div>
            </section>
        );
    }
    return null;
};

export default CookiesManager;
