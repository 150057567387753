import { ReactNode } from 'react';
import Nav from 'src/components/nav/Nav';
import cx from 'classnames';

interface Props {
    children: ReactNode;
    isLoggedIn: boolean;
    classnames?: string;
}

const VerticalDivideWindow: React.FC<Props> = ({ isLoggedIn, children, classnames }) => {
    return (
        <>
            <Nav isLoggedIn={isLoggedIn} />
            <div className={cx(['h-full w-full flex flex-row', classnames])}>{children}</div>
        </>
    );
};
export default VerticalDivideWindow;
