import cx from 'classnames';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
interface Props {
    children: ReactNode;
    classnames?: string;
}

const Popover: React.FC<Props> = ({ children, classnames }) => {
    const [isPopVisible, setPopVisibility] = useState(false);
    const [popPosition, setPopPosition] = useState({ x: 0, y: 0 });
    const [popWidth, setPopWidth] = useState(500);
    const [ParentPosition, setParentPos] = useState({ x: 0, y: 0 });
    const popRef = useRef();

    const handleMouseMove = (e: React.MouseEvent) => {
        const { clientX, clientY } = e;
        const bodyData = document.body.getBoundingClientRect();
        if (window.innerWidth < popWidth) setPopWidth(window.innerWidth * 0.9);
        if (clientX + popWidth / 2 > bodyData.width)
            setPopPosition({ x: bodyData.width - popWidth / 2, y: clientY });
        else if (clientX - popWidth / 2 < 0) setPopPosition({ x: popWidth / 2, y: clientY });
        else setPopPosition({ x: clientX, y: clientY });
    };
    const handleMouseEnter = (e: React.MouseEvent) => {
        const parentData = (e.target as HTMLElement).getBoundingClientRect();
        setParentPos({ x: parentData.left, y: parentData.top });
        setPopVisibility(true);
    };
    return (
        <>
            <div
                className="popover"
                onMouseEnter={handleMouseEnter}
                onMouseMove={handleMouseMove}
                onMouseLeave={() => setPopVisibility(false)}
            >
                <span className="hover">?</span>
            </div>
            {isPopVisible &&
                createPortal(
                    <div style={{ overflow: 'hidden' }}>
                        <div
                            className={classnames}
                            ref={popRef}
                            style={{
                                top: popPosition.y + 30,
                                left: popPosition.x,
                                transform: 'translateX(-50%)',
                                position: 'fixed',
                                textAlign: 'center',
                                padding: '.5em',
                                borderRadius: '1.3em',
                                background: 'var(--default)',
                                color: 'var(--grayscale70)',
                                width: 'max-content',
                                maxWidth: popWidth,
                                zIndex: '1000'
                            }}
                        >
                            {children}
                        </div>
                        <span
                            style={{
                                top: popPosition.y + 25,
                                left: ParentPosition.x,
                                zIndex: '0',
                                transform: 'rotate(45deg)',
                                position: 'fixed',
                                width: '15px',
                                height: '15px',
                                background: 'var(--default)'
                            }}
                        ></span>
                        <span
                            style={{
                                top: popPosition.y + 32,
                                left: ParentPosition.x,
                                zIndex: '0',
                                transform: 'translateX(-3px)',
                                position: 'fixed',
                                width: '21px',
                                height: '50px',
                                background: 'var(--default)'
                            }}
                        ></span>
                    </div>,
                    document.body
                )}
        </>
    );
};

export default Popover;
