import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useResetPassword } from 'src/hooks/react-query/authQueries';
import { ResetPassSchema } from 'src/consts/schemas';
import cx from 'classnames';
import Input from 'src/components/forms/input/Input';
import Spinner from 'src/components/Spinner';

import image from 'src/assets/images/login_image.svg';
import VerticalDivideWindow from 'src/groups/VerticalDivideWindow';
import FlexAlign from 'src/groups/FlexAlign';
import Button from 'src/components/button/Button';
import Gradient from 'src/groups/Gradient';

interface Props {
    isLoggedIn: boolean;
}

const ResetPasswordPage: React.FC<Props> = ({ isLoggedIn }) => {
    const { token } = useParams<{ token: any }>();

    const resetPassword = useResetPassword();
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: ''
        },
        onSubmit: async (values) => {
            try {
                await resetPassword.mutateAsync({
                    token: token,
                    password: formik.values.password
                });
                navigate('/');
            } catch (error) {
                formik.setStatus({ _form: 'error' });
            }
        },
        validationSchema: ResetPassSchema,
        validateOnBlur: true,
        validateOnChange: false,
        enableReinitialize: true
    });

    const isFormError = () => {
        if (formik.status && formik.status._form && formik.status._form === 'error') {
            return true;
        }
        return false;
    };
    return (
        <VerticalDivideWindow classnames="text-grayscale-00" isLoggedIn={isLoggedIn}>
            <div className="flex-1">
                <FlexAlign>
                    <div className={cx('my-[auto] w-[80%] max-w-[450px] z-10')}>
                        <h1 className="mb-[10px] text-center">Resetowanie hasła</h1>
                        <p className="font-regular-14 text-center mb-[30px]">
                            Utwórz nowe, silne hasło, którego nie używasz na innych stronach.
                        </p>
                        <form className="flex items-center flex-col" onSubmit={formik.handleSubmit}>
                            <div className="w-full flex-1 flex flex-col ">
                                <Input
                                    value={formik.values.password}
                                    id={'password'}
                                    type={'password'}
                                    placeholder={'Wpisz hasło'}
                                    name={'password'}
                                    handleChange={formik.handleChange}
                                    isError={!!formik.errors.password || isFormError()}
                                    errorMessage={formik.errors.password}
                                    classnames="mb-[40px]"
                                />
                                <Input
                                    value={formik.values.confirmPassword}
                                    id={'confirmPassword'}
                                    type={'password'}
                                    placeholder={'Potwierdź hasło'}
                                    name={'confirmPassword'}
                                    handleChange={formik.handleChange}
                                    isError={!!formik.errors.confirmPassword || isFormError()}
                                    errorMessage={formik.errors.confirmPassword}
                                    classnames="mb-[40px]"
                                />
                            </div>
                            {isFormError() && (
                                <div className="w-full flex-1 flex-col mb-3">
                                    <span className="text-danger">Houston, mamy problem!</span>
                                    <ul className="list-inside list-disc text-danger">
                                        <li>Sprawdź, czy wpisałeś takie same hasła</li>
                                        <li>Sprawdź, czy hasła zawierają 8 znaków</li>
                                    </ul>
                                </div>
                            )}
                            <Button
                                type="submit"
                                disabled={resetPassword.isLoading}
                                size={'large'}
                                styleType={'primary'}
                            >
                                {resetPassword.isLoading ? <Spinner /> : 'Potwierdź'}
                            </Button>
                        </form>
                    </div>
                </FlexAlign>
            </div>
            <Gradient classnames="flex-1 block mdlg:hidden">
                <FlexAlign>
                    <img className="my-[auto] ml-[-170px]" src={image} />
                </FlexAlign>
            </Gradient>
        </VerticalDivideWindow>
    );
};

export default ResetPasswordPage;
