import React, { ReactElement, ReactHTMLElement, useEffect, useRef, useState } from 'react';

import { useFetchMyPlan, useFetchProducts } from 'src/hooks/react-query/pricingQueries';
import CardDeck from 'src/pages/LandingPage/CardDeck';
import LoadingPricingCard from 'src/pages/LandingPage/LoadingPricingCard';
import AnimatedText from 'src/components/pricing/AnimatedText';
import { UseQueryResult } from 'react-query';
import { Products } from 'src/interfaces/Pricing';
import { AxiosError } from 'axios';

import icon from 'src/assets/icons/done.svg';
import HorizontalDivideWindow from 'src/groups/HorizontalDivideWindow';
import Gradient from 'src/groups/Gradient';
import BorderBox from 'src/groups/BorderBox';
import Button from 'src/components/button/Button';
import Spinner from 'src/components/Spinner';
import { toast } from 'react-toastify';
import Popover from 'src/components/popover/Popover';

const Icon: React.FC = () => {
    const iconRef = useRef();
    const [svg, setSvg] = useState('');
    const el = iconRef.current as HTMLElement;
    useEffect(() => {
        fetch(icon)
            .then((response) => response.text())
            .then((svgContent) => {
                setSvg(svgContent);
            });
        if (el && el.children) {
            el.children[0].classList.add(
                'w-[9px]',
                'h-[6px]',
                'text-grayscale-70',
                'absolute',
                'top-1/2',
                'left-1/2',
                '-translate-x-1/2',
                '-translate-y-1/2'
            );
        }
    }, [el && el.children]);
    return (
        <span
            className="inline-block w-[18px] h-[18px] rounded-[50%] bg-grayscale-30 relative"
            ref={iconRef}
            dangerouslySetInnerHTML={{ __html: svg }}
        ></span>
    );
};

const Badge = () => {
    return (
        <span className="font-semibold-10 px-[8px] py-[4px] rounded-[20px] bg-[#FBE6E9]">
            Wkrótce
        </span>
    );
};

const Point = ({ text, basic, premium, biznes }) => {
    return (
        <div className="flex flex-row justify-between py-3 border-b-[1px] border-grayscale-50 md:flex-col">
            <span className="max-w-[550px] font-semibold-14 md:max-w-full md:text-center md:pb-10">
                {text}
            </span>
            <div className="my-auto">
                <div className="flex flex-row gap-[52px] w-[600px] md:w-full">
                    <div className="flex-1 block m-auto text-center">{basic}</div>
                    <div className="flex-1 block m-auto text-center">{premium}</div>
                    <div className="flex-1 block m-auto text-center">{biznes}</div>
                </div>
            </div>
        </div>
    );
};

const LandingPage: React.FC<any> = ({ isLoggedIn }) => {
    const products = useFetchProducts();
    const Points = useRef(null);
    const [showEmail, setShowEmail] = useState(false);
    const copyValue = (value) => {
        navigator.clipboard.writeText(value);
        toast.success('Skopiowano do schowka');
    };
    const handleScroll = () => {
        const toggleShadow = (el: HTMLElement) => {
            const PriceMenu = document.getElementById('PriceMenu');
            if (el.scrollTop == 0) PriceMenu.classList.remove('shadow');
            else PriceMenu.classList.add('shadow');
        };
        if (Points.current)
            Points.current.addEventListener('scroll', (el) => toggleShadow(el.target));
        return () => {
            if (Points.current)
                Points.current.removeEventListener('scroll', (el) => toggleShadow(el.target));
        };
    };

    return (
        <>
            <HorizontalDivideWindow isLoggedIn={isLoggedIn}>
                <Gradient classnames="flex-2"></Gradient>
                <div className="flex-4"></div>
            </HorizontalDivideWindow>
            <div
                className="w-full absolute top-[120px] left-[50%] translate-x-[-50%] xxs:h-[auto]"
                style={{ height: 'calc(100vh - 120px - 20px)' }}
            >
                <BorderBox classnames="m-auto w-11/12 h-full px-0 py-0">
                    {products.isLoading ? (
                        <div className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] scale-[3]">
                            <Spinner />
                        </div>
                    ) : (
                        <>
                            <div
                                id="PriceMenu"
                                className="px-[40px] py-[20px] flex flex-row justify-between z-10 md:flex-col md:gap-10 xxs:gap-0 xxs:h-full xxs:px-5"
                            >
                                <div className="flex flex-col justify-between gap-3 md:max-w-full">
                                    <h2 className="md:text-center">
                                        Wybierz plan dopasowany do Twoich potrzeb
                                    </h2>
                                    <p className="font-regular-14 md:text-center">
                                        Twórz bezbłędne, zrozumiałe i kreatywne teksty z dodatkiem
                                        GoodWrite
                                    </p>
                                    <div className="w-[88px] h-[4px] rounded-[8px] bg-default md:w-full"></div>
                                </div>
                                <div>
                                    <div className="flex flex-row gap-[52px] w-[600px] h-full md:w-full xxs:flex-col xxs:gap-20">
                                        <div className="flex-1 flex flex-col justify-between gap-2 xxs:gap-3">
                                            <h4 className="text-center xxs:hidden">Podstawowy</h4>
                                            <h2 className="hidden text-center xxs:block">
                                                Podstawowy
                                            </h2>
                                            <div className="font-regular-14 text-grayscale-10 text-center">
                                                <h3 className="inline-block text-grayscale-00">
                                                    0 zł&nbsp;
                                                </h3>
                                                <span className="align-top">&nbsp;/ miesiąc</span>
                                            </div>
                                            <p className="font-regular-12 text-grayscale-20 text-center">
                                                Za darmo na zawsze
                                            </p>
                                            <Button
                                                size={'small'}
                                                styleType={'primary'}
                                                type={'button'}
                                                classnames="w-full inline-block"
                                            >
                                                Wybierz
                                            </Button>
                                        </div>
                                        <div className="flex-1 flex flex-col justify-between relative gap-2 xxs:gap-3">
                                            <h4 className="text-center xxs:hidden">Premium</h4>
                                            <h2 className="hidden text-center xxs:block">
                                                Premium
                                            </h2>
                                            <div className="font-regular-14 text-grayscale-10 text-center">
                                                <h3 className="inline-block text-grayscale-00 mr-1">
                                                    {`${(products.data[0].prices[0].price / 100)
                                                        .toFixed(2)
                                                        .replace('.', ',')} `}
                                                    zł
                                                </h3>
                                                <span className="align-top">
                                                    &nbsp;/&nbsp;miesiąc
                                                </span>
                                            </div>
                                            <p className="font-regular-12 text-grayscale-20 text-center">
                                                Możesz anulować w dowolnym momencie.
                                            </p>
                                            <Button
                                                size={'small'}
                                                styleType={'primary'}
                                                type={'button'}
                                                classnames="w-full inline-block"
                                            >
                                                Wybierz
                                            </Button>
                                            <div
                                                className="z-[-1] absolute top-[-33px] left-1/2 -translate-x-1/2 border-[1px] border-default rounded-[20px] px-[12px] py-[4px] bg-light2 md:rounded-[5px] md:top-[-30px] md:h-[135%] md:w-[110%]"
                                                style={{ width: 'calc(100% + 1rem)' }}
                                            >
                                                <p className="font-bold-12 text-default m-auto text-center">
                                                    7 DNI ZA DARMO
                                                </p>
                                            </div>
                                        </div>
                                        <div className="flex-1 flex flex-col justify-between gap-2 xxs:gap-3">
                                            <h4 className="text-center xxs:hidden">Biznes</h4>
                                            <h2 className="hidden text-center xxs:block">Biznes</h2>
                                            <p className="font-bold-14 text-center">
                                                Wycena indywidualna
                                            </p>
                                            {!showEmail ? (
                                                <Button
                                                    size={'small'}
                                                    styleType={'primary'}
                                                    type={'button'}
                                                    classnames="w-full inline-block"
                                                    onClick={() => {
                                                        setShowEmail((el) => !el);
                                                        copyValue('kontakt@goodwrite.pl');
                                                    }}
                                                >
                                                    Wybierz
                                                </Button>
                                            ) : (
                                                <Button
                                                    size={'small'}
                                                    styleType={'secondary'}
                                                    type={'button'}
                                                    classnames="w-full inline-block"
                                                    onClick={() =>
                                                        copyValue('kontakt@goodwrite.pl')
                                                    }
                                                >
                                                    kontakt@goodwrite.pl
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                id="Points"
                                className="xxs:hidden scroll"
                                ref={(el) => {
                                    Points.current = el;
                                    handleScroll();
                                }}
                            >
                                <Point
                                    text={
                                        <span>
                                            Dodatek do przeglądarki Google Chrome, Mozilla Firefox i
                                            Microsoft Edge{' '}
                                            <Popover>
                                                Plugin GoodWrite podpina się do: Microsoft 365
                                                Online, Wordpress, różnych serwisów pocztowych (np.
                                                Gmail, WP, Interia, Onet), platform
                                                społecznościowych, np. Facebook, Linkedin, X
                                                (dawniej Twitter) oraz jeszcze wielu innych serwisów
                                                internetowych (m.in. YouTube, Jira czy Trello)
                                            </Popover>
                                        </span>
                                    }
                                    basic={<Icon />}
                                    premium={<Icon />}
                                    biznes={<Icon />}
                                />
                                <Point
                                    text="Zaawansowana korekta językowa (w zakresie pisowni i gramatyki)"
                                    basic={<Icon />}
                                    premium={<Icon />}
                                    biznes={<Icon />}
                                />
                                <Point
                                    text="Inteligentne rozpoznawanie błędów wynikających z szybkiego pisania"
                                    basic={<Icon />}
                                    premium={<Icon />}
                                    biznes={<Icon />}
                                />
                                <Point
                                    text="Wbudowany słownik synonimów"
                                    basic={undefined}
                                    premium={<Icon />}
                                    biznes={<Icon />}
                                />
                                <Point
                                    text="Parafrazowanie zdań z udziałem bogatego i oryginalnego słownictwa"
                                    basic={undefined}
                                    premium={<Icon />}
                                    biznes={<Icon />}
                                />
                                <Point
                                    text="Wykrywanie wyrażeń wulgarnych i nieetycznych"
                                    basic={undefined}
                                    premium={<Icon />}
                                    biznes={<Icon />}
                                />
                                <Point
                                    text="Ocena stylu tekstu z sugestiami stylistycznymi"
                                    basic={undefined}
                                    premium={<Badge />}
                                    biznes={<Badge />}
                                />
                                <Point
                                    text="Ocena zrozumiałości tekstu"
                                    basic={undefined}
                                    premium={<Badge />}
                                    biznes={<Badge />}
                                />
                                <Point
                                    text="Pakiety grupowe dla wielu użytkowników"
                                    basic={undefined}
                                    premium={undefined}
                                    biznes={<Icon />}
                                />
                                <Point
                                    text="Zarządzanie użytkownikami w ramach pakietu"
                                    basic={undefined}
                                    premium={undefined}
                                    biznes={<Icon />}
                                />
                                <Point
                                    text="Jedna faktura dla wszystkich użytkowników"
                                    basic={undefined}
                                    premium={undefined}
                                    biznes={<Icon />}
                                />
                                <Point
                                    text={
                                        <ol className="list-disc md:list-none">
                                            Dostęp do API:
                                            <li className="ml-5 md:ml-0">poprawy tekstu</li>
                                            <li className="ml-5 md:ml-0">
                                                synonimów i kreatywnych sugestii
                                            </li>
                                            <li className="ml-5 md:ml-0">
                                                wykrywania wulgaryzmów i wyrażeń nieetycznych
                                            </li>
                                        </ol>
                                    }
                                    basic={undefined}
                                    premium={undefined}
                                    biznes={<Icon />}
                                />
                                <Point
                                    text="Pomoc konsultanta"
                                    basic={undefined}
                                    premium={undefined}
                                    biznes={<Icon />}
                                />
                            </div>
                        </>
                    )}
                </BorderBox>
            </div>
        </>
    );
};

export default LandingPage;
