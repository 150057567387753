import { ReactNode } from 'react';
import cx from 'classnames';
import FlexAlign from './FlexAlign';
interface Props {
    children?: ReactNode;
    classnames?: string;
}

const Gradient: React.FC<Props> = ({ children, classnames }) => {
    return (
        <div
            style={{ background: 'linear-gradient(147deg, #F5EEFE 45.55%, #FFE4E2 100%)' }}
            className={cx([classnames])}
        >
            {children}
        </div>
    );
};
export default Gradient;
