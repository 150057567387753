import { FormikHandlers } from 'formik';
import cx from 'classnames';
import icon from 'src/assets/icons/done.svg';
import { useEffect, useState } from 'react';

interface Props {
    name: string;
    id: string;
    classnames?: string;
    type: string;
    onChange: FormikHandlers['handleChange'];
    checked: boolean;
    disabled?: boolean;
}
const Checkbox: React.FC<Props> = ({ name, id, classnames, type, onChange, checked, disabled }) => {
    const [Icon, setIcon] = useState('');
    useEffect(() => {
        fetch(icon)
            .then((response) => response.text())
            .then((svgContent) => {
                setIcon(svgContent);
            });
    });
    return (
        <div className={cx(['checkbox', classnames])}>
            <input
                type={type}
                name={name}
                id={id}
                onChange={onChange}
                checked={checked}
                disabled={disabled}
            />
            <label htmlFor={id} dangerouslySetInnerHTML={{ __html: Icon }} />
        </div>
    );
};

export default Checkbox;
