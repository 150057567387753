import { FormikHandlers } from 'formik';
import cx from 'classnames';
import { boolean } from 'yup';

interface Props {
    name: string;
    id: string;
    classnames?: string;
    type: string;
    onChange: FormikHandlers['handleChange'];
    checked: boolean;
    disabled?: boolean;
}
const Checkbox: React.FC<Props> = ({ name, id, classnames, type, onChange, checked, disabled }) => {
    return (
        <div className={cx(['switch', classnames])}>
            <input
                type={type}
                name={name}
                id={id}
                onChange={onChange}
                checked={checked}
                disabled={disabled}
            />
            <label htmlFor={id} />
        </div>
    );
};

export default Checkbox;
