import React, { ChangeEvent, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { RegistrationSchema } from 'src/consts/schemas';

import { useRegister } from 'src/hooks/react-query/authQueries';
import Input from 'src/components/forms/input/Input';
import cx from 'classnames';
import Spinner from 'src/components/Spinner';
import { AxiosResponse } from 'axios';

import image from 'src/assets/images/register_image.svg';
import FlexAlign from 'src/groups/FlexAlign';
import VerticalDivideWindow from 'src/groups/VerticalDivideWindow';
import Gradient from 'src/groups/Gradient';
import Quicklink from 'src/components/quicklink/Quicklink';
import Checkbox from 'src/components/forms/selection/Checkbox';
import Button from 'src/components/button/Button';
interface Props {
    isLoggedIn: boolean;
}

const RegistrationPage: React.FC<Props> = ({ isLoggedIn }) => {
    const register = useRegister();
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            first_name: '',
            email: '',
            password: '',
            confirmPassword: '',
            acceptTermsAndConditions: false,
            newsletter: false
        },
        initialErrors: {
            first_name: '',
            email: '',
            password: '',
            confirmPassword: '',
            acceptTermsAndConditions: '',
            newsletter: ''
        },
        onSubmit: async ({ email, password, first_name, newsletter, acceptTermsAndConditions }) => {
            formik.setStatus({ _form: '' });
            try {
                await register.mutateAsync({
                    email,
                    password,
                    first_name,
                    newsletter,
                    acceptTermsAndConditions
                });
                navigate('/logowanie');
            } catch (error) {
                formik.setErrors({
                    email: 'Podany e-mail jest już zajęty'
                });
            }
        },
        validationSchema: RegistrationSchema,
        validateOnBlur: true,
        validateOnChange: false,
        enableReinitialize: true
    });
    const validPassword = (e) => {
        formik.handleChange(e);
        formik.validateField('password');
    };
    const isFormError = () => {
        if (formik.status && formik.status._form && formik.status._form === 'error') {
            return true;
        }
        return false;
    };

    return (
        <VerticalDivideWindow classnames="text-grayscale-00" isLoggedIn={isLoggedIn}>
            <div className="flex-1">
                <FlexAlign classnames=" pt-[120px] pb-[20px] min-h-[600px]">
                    <div className="h-full my-[auto] w-[80%] max-w-[450px] z-10 flex flex-col">
                        <div className="flex-1">
                            <h1 className="text-center">Rejestracja</h1>
                        </div>
                        <div className="flex-[5]">
                            <form
                                onSubmit={formik.handleSubmit}
                                className="h-full w-full font-semibold-12 flex flex-col justify-between gap-3"
                                noValidate
                            >
                                <div className="flex-2 flex flex-col justify-between gap-3">
                                    <Input
                                        value={formik.values['first_name']}
                                        id="first_name"
                                        type={'text'}
                                        placeholder={'Wpisz imię'}
                                        name={'first_name'}
                                        handleChange={formik.handleChange}
                                        isError={!!formik.errors.first_name || isFormError()}
                                        errorMessage={formik.errors.first_name}
                                    />
                                    <Input
                                        value={formik.values['email']}
                                        id={'email'}
                                        type={'email'}
                                        placeholder={'Wpisz email'}
                                        name={'email'}
                                        handleChange={formik.handleChange}
                                        isError={!!formik.errors.email || isFormError()}
                                        errorMessage={formik.errors.email}
                                    />
                                    <Input
                                        value={formik.values['password']}
                                        id={'password'}
                                        type={'password'}
                                        placeholder={'Wpisz hasło'}
                                        name={'password'}
                                        handleChange={validPassword}
                                        isError={!!formik.errors.password || isFormError()}
                                        errorMessage={formik.errors.password}
                                    />
                                    <Input
                                        value={formik.values['confirmPassword']}
                                        id={'confirmPassword'}
                                        type={'password'}
                                        placeholder={'Powtórz hasło'}
                                        name={'confirmPassword'}
                                        handleChange={formik.handleChange}
                                        isError={!!formik.errors.confirmPassword || isFormError()}
                                        errorMessage={formik.errors.confirmPassword}
                                    />
                                </div>
                                <div className="flex-1 flex flex-col justify-center gap-2">
                                    <div className="flex flex-row gap-[8px]">
                                        <span>
                                            <Checkbox
                                                type="checkbox"
                                                checked={formik.values.acceptTermsAndConditions}
                                                name={'acceptTermsAndConditions'}
                                                onChange={formik.handleChange}
                                                id={'acceptTermsAndConditions'}
                                            ></Checkbox>
                                        </span>
                                        <span>
                                            <label
                                                className="select-none"
                                                style={{ color: 'var(--error)' }}
                                            >
                                                *
                                            </label>
                                            {' Akceptuję '}
                                            <Quicklink
                                                classnames="no-underline"
                                                size={'small'}
                                                to={'https://goodwrite.pl/regulamin/'}
                                                target="_blank"
                                            >
                                                regulamin
                                            </Quicklink>
                                            {' oraz '}
                                            <Quicklink
                                                classnames="no-underline"
                                                size={'small'}
                                                to={'https://goodwrite.pl/polityka-prywatnosci/'}
                                                target="_blank"
                                            >
                                                politykę prywatności
                                            </Quicklink>{' '}
                                            GoodWrite
                                        </span>
                                    </div>
                                    <div className="flex flex-row gap-[8px]">
                                        <span>
                                            <Checkbox
                                                type="checkbox"
                                                checked={formik.values.newsletter}
                                                name={'newsletter'}
                                                onChange={formik.handleChange}
                                                id={'newsletter'}
                                            ></Checkbox>
                                        </span>
                                        <span className="select-none">
                                            Chcę otrzymywać newsletter o ofertach specjalnych i
                                            dostępnych kuponach rabatowych.
                                        </span>
                                    </div>
                                    <p className="text-grayscale-20 ml-[25px]">* Wymagane zgody</p>
                                </div>
                                <div className="flex-1 flex flex-col gap-4">
                                    {/* {isFormError() && (
                                        <div className="w-fullflex-col">
                                            <span className="text-danger">
                                                Houston mamy problem!
                                            </span>
                                        </div>
                                    )} */}
                                    <Button
                                        size={'large'}
                                        styleType={'primary'}
                                        type={'submit'}
                                        classnames="block w-full"
                                    >
                                        {register.isLoading ? (
                                            <span>
                                                <Spinner />
                                            </span>
                                        ) : (
                                            'ZAŁÓŻ KONTO'
                                        )}
                                    </Button>
                                    <p className="font-semibold-14 text-center">
                                        Masz już Konto?{' '}
                                        <Quicklink size="medium" to={'/logowanie'}>
                                            Zaloguj się
                                        </Quicklink>
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </FlexAlign>
            </div>
            <Gradient classnames="flex-1 block mdlg:hidden">
                <FlexAlign>
                    <img
                        className="my-[auto] ml-[-170px] w-[90%]"
                        // style={{ width: '' }}
                        src={image}
                    />
                </FlexAlign>
            </Gradient>
        </VerticalDivideWindow>
    );
};

export default RegistrationPage;
