import React, { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { LoginSchema } from 'src/consts/schemas';
import { useLogin } from 'src/hooks/react-query/authQueries';
import { LoginRequest } from 'src/interfaces/auth/LoginInterfaces';
import { setToken } from 'src/helpers/token';
import { setLoggedIn } from 'src/store/slices/authSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/store';
import Input from 'src/components/forms/input/Input';
import cx from 'classnames';
import Spinner from 'src/components/Spinner';
import { useSubscribe } from 'src/hooks/react-query/pricingQueries';
import VerticalDivideWindow from 'src/groups/VerticalDivideWindow';
import Gradient from 'src/groups/Gradient';

import image from 'src/assets/images/login_image.svg';
import FlexAlign from 'src/groups/FlexAlign';
import letter from 'src/assets/icons/letter.svg';
import lock from 'src/assets/icons/lock.svg';
import eyeX from 'src/assets/icons/eye-x.svg';
import eyeOff from 'src/assets/icons/eye-off.svg';
import Quicklink from 'src/components/quicklink/Quicklink';
import Button from 'src/components/button/Button';

interface Props {
    isLoggedIn: boolean;
}

const LoginPage: React.FC<Props> = ({ isLoggedIn }) => {
    const login = useLogin();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const subscribe = useSubscribe();
    const [showPass, setShowPass] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        initialErrors: {
            email: '',
            password: ''
        },
        onSubmit: async ({ email, password }) => {
            const data: LoginRequest = {
                username: email,
                password
            };
            formik.setStatus({ _form: '' });
            try {
                const response = await login.mutateAsync(data);

                setToken(response.data.access_token);
                dispatch(setLoggedIn(true));

                const priceIdFromUrl = searchParams.get('priceId');

                if (priceIdFromUrl) {
                    await subscribe.mutateAsync({ price_id: priceIdFromUrl });
                }
                navigate('/');
            } catch (error) {
                if (typeof error.response.data.detail === 'string') {
                    switch (error.response.data.detail) {
                        case 'LOGIN_BAD_CREDENTIALS':
                            formik.setStatus({ _form: 'error' });
                            break;
                        default:
                            formik.setStatus({ _form: 'error' });
                            break;
                    }
                }
                dispatch(setLoggedIn(false));
            }
        },
        validationSchema: LoginSchema,
        validateOnBlur: true,
        validateOnChange: false,
        enableReinitialize: true
    });

    const isFormError = () => {
        if (formik.status && formik.status._form && formik.status._form === 'error') {
            return true;
        }
        return false;
    };
    const eye_img = (
        <img
            className="opacity-30 cursor-pointer"
            src={showPass ? eyeX : eyeOff}
            onClick={() => setShowPass((el) => !el)}
        />
    );
    const login_img = <img src={letter} />;
    const pass_img = <img src={lock} />;
    return (
        <VerticalDivideWindow classnames="text-grayscale-00" isLoggedIn={isLoggedIn}>
            <div className="flex-1">
                <FlexAlign>
                    <div className={cx('my-[auto] w-[80%] max-w-[450px] z-10')}>
                        <h1 className="mb-[60px] text-center">Logowanie</h1>
                        <form onSubmit={formik.handleSubmit} className="w-full" noValidate>
                            <Input
                                value={formik.values['email']}
                                id="email"
                                type={'email'}
                                placeholder={'Wpisz e-mail'}
                                name={'email'}
                                handleChange={formik.handleChange}
                                isError={!!formik.errors.email || isFormError()}
                                errorMessage={formik.errors.email}
                                iconLeft={login_img}
                                classnames="mb-[24px]"
                            />
                            <Input
                                value={formik.values['password']}
                                id={'password'}
                                type={showPass ? 'text' : 'password'}
                                placeholder={'Wpisz hasło'}
                                name={'password'}
                                handleChange={formik.handleChange}
                                isError={!!formik.errors.password || isFormError()}
                                errorMessage={formik.errors.password}
                                iconLeft={pass_img}
                                iconRight={eye_img}
                                classnames="mb-[24px]"
                            />
                            <Quicklink
                                classnames="block w-full text-right mb-[10px]"
                                to={'/zapomnialem-hasla'}
                                size={'large'}
                            >
                                Nie pamiętasz hasła?
                            </Quicklink>
                            {isFormError() && (
                                <div className="w-full font-bold-12">
                                    <span className="text-error">Houston, mamy problem!</span>
                                    <ul className="list-inside list-disc text-error">
                                        <li>Sprawdź, czy wpisałeś poprawny email</li>
                                        <li>Sprawdź, czy wpisałeś poprawne hasło</li>
                                        <li>Sprawdź, czy nie masz włączonego caps lock</li>
                                    </ul>
                                </div>
                            )}
                            <Button
                                classnames={cx(['block w-full mt-[30px] mb-[40px]'])}
                                size={'large'}
                                styleType={'primary'}
                                type={'submit'}
                            >
                                {login.isLoading ? (
                                    <span>
                                        <Spinner />
                                    </span>
                                ) : (
                                    'ZALOGUJ SIE'
                                )}
                            </Button>
                            <p className="font-semibold-14 text-center">
                                Nie masz konta?
                                <Quicklink
                                    classnames="cursor-pointer ml-1"
                                    to={'/rejestracja'}
                                    size={'medium'}
                                >
                                    Zarejestruj się.
                                </Quicklink>
                            </p>
                        </form>
                    </div>
                </FlexAlign>
            </div>
            <Gradient classnames="flex-1 block mdlg:hidden">
                <FlexAlign>
                    <img className="my-[auto] ml-[-170px]" src={image} />
                </FlexAlign>
            </Gradient>
        </VerticalDivideWindow>
    );
};

export default LoginPage;
