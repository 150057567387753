import React, { useEffect, useState } from 'react';
import './App.scss';
import { useRoutes, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage/LoginPage';
import RegistrationPage from 'src/pages/RegistrationPage/RegistrationPage';
import LandingPage from 'src/pages/LandingPage/LandingPage';
import ConfirmEmailPage from 'src/pages/ConfirmEmailPage/ConfirmEmailPage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from 'src/store';
import { getToken } from 'src/helpers/token';
import { setLoggedIn } from 'src/store/slices/authSlice';
import Nav from 'src/components/nav/Nav';

import useBackgroundModal from 'src/hooks/useBackgroundModal';
import { renderBasicSwal } from 'src/helpers/utils';
import Profile from 'src/pages/Profile/Profile';
import PostInstallation from 'src/pages/PostInstallation';
import CookiesManager from 'src/components/CookiesManager';
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage/ResetPasswordPage';
import Test from './pages/Test/Test';
import ManagePlan from './pages/ManagePlan/ManagePlan';

const App: React.FC = () => {
    useBackgroundModal(
        'payment',
        () => renderBasicSwal('Subskrybujesz GoodWrite!', 'Płatność powiodła się', 'success'),
        () => renderBasicSwal('Coś poszło nie tak!', 'Płatność nie powiodła się', 'error')
    );
    const [isLoading, setIsLoading] = useState(true);

    const isLoggedIn = useSelector((state: AppState) => state.auth.isLoggedIn);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        const token = getToken();
        if (token) {
            dispatch(setLoggedIn(true));
        }
        setIsLoading(false);
    }, [dispatch]);

    const notAuthRoutes = useRoutes([
        {
            path: `/test`,
            element: <Test />
        },
        {
            path: `/cennik`,
            element: <LandingPage isLoggedIn={isLoggedIn} />
        },
        {
            path: `/thank-you`,
            element: <PostInstallation />
        },
        {
            path: `/rejestracja`,
            element: <RegistrationPage isLoggedIn={isLoggedIn} />
        },
        {
            path: `/zarejestrowano`,
            element: <ConfirmEmailPage />
        },
        {
            path: `/logowanie`,
            element: <LoginPage isLoggedIn={isLoggedIn} />
        },
        {
            path: '/zapomnialem-hasla',
            element: <ForgotPasswordPage isLoggedIn={isLoggedIn} />
        },
        {
            path: '/reset-hasla/:token',
            element: <ResetPasswordPage isLoggedIn={isLoggedIn} />
        },
        {
            path: '*',
            element: <Navigate replace to={`/logowanie`} />
        }
    ]);
    const authRoutes = useRoutes([
        {
            path: `/test`,
            element: <Test />
        },
        {
            path: `/thank-you`,
            element: <PostInstallation />
        },
        {
            path: `/plan`,
            element: <ManagePlan />
        },
        {
            path: '/cennik',
            element: <LandingPage />
        },
        {
            path: '*',
            element: <Profile />
        }
    ]);
    if (isLoading) {
        return <div className="bg-default h-100"></div>;
    } else {
        return (
            <>
                <main className="h-screen w-screen">
                    {!isLoggedIn ? notAuthRoutes : authRoutes}
                    <CookiesManager />
                </main>
                <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} autoClose={5000} />
            </>
        );
    }
};

export default App;
