import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Gradient from 'src/groups/Gradient';
import BorderBox from 'src/groups/BorderBox';
import Button from 'src/components/button/Button';
import leaf1 from 'src/assets/images/leaf1.svg';
import leaf2 from 'src/assets/images/leaf2.svg';
import HorizontalDivideWindow from 'src/groups/HorizontalDivideWindow';

const ManagePlan: React.FC = () => {
    const [showEmail, setShowEmail] = useState(false);
    const copyValue = (value) => {
        navigator.clipboard.writeText(value);
        toast.success('Skopiowano do schowka');
    };
    return (
        <>
            <div className="absolute top-[45%] left-[7%]">
                <img src={leaf1} />
                <img src={leaf2} className="-rotate-90" />
            </div>
            <div className="absolute top-[15%] left-[75%] rotate-180 nav:left-[30%] nav:top-[0px] nav:rotate-[100deg]">
                <img src={leaf1} className=" rotate-[20deg] scale-x-[1] scale-y-[-1]" />
                <img src={leaf2} className="-rotate-45 scale-x-[1] scale-y-[-1]" />
            </div>
            <div className="w-4/6 absolute top-[50%] left-[50%] translate-y-[-50%] translate-y-[-50%] translate-x-[-50%] flex gap-[20px] mdlg:flex-col nav:translate-y-[-30%] nav:w-5/6">
                <BorderBox classnames="flex-1 justify-between">
                    <h4 className="mb-[20px]">Premium</h4>
                    <p className="mb-[20px]">
                        Stworzony z myślą o tych, którzy na co dzień pracują ze słowem pisanym:
                        marketerów, PR-owców, dziennikarzy, redaktorów portali newsowych...
                    </p>
                    <div className="font-regular-14 text-grayscale-10 mb-[8px]">
                        <h3 className="inline-block text-grayscale-00">24,99 zł&nbsp;</h3>
                        <span className="align-top">&nbsp;/ miesiąc</span>
                    </div>
                    <p className="font-regular-14 text-grayscale-20 mb-[20px]">
                        Możesz anulować w dowolnym momencie.
                    </p>
                    <div>
                        <div className="w-full h-[1px] bg-grayscale-40 mb-[28px]"></div>
                        <Button
                            classnames="inline w-full"
                            size={'large'}
                            styleType={'primary'}
                            type={'button'}
                        >
                            WYBIERZ
                        </Button>
                    </div>
                </BorderBox>
                <BorderBox classnames="flex-1 justify-between">
                    <h4 className="mb-[20px]">Biznes</h4>
                    <p className="mb-[20px]">
                        Najlepszy dla tych, którzy chcą, wprowadzić swoje narzędzia i aplikacje na
                        nowe tory, dodając do nich API GoodWrite...
                    </p>
                    <h3 className="inline-block text-grayscale-00 mb-[47px]">
                        Wycena indywidualna
                    </h3>
                    <div>
                        <div className="w-full h-[1px] bg-grayscale-40 mb-[28px]"></div>
                        {!showEmail ? (
                            <Button
                                classnames="inline w-full"
                                size={'large'}
                                styleType={'primary'}
                                type={'button'}
                                onClick={() => {
                                    setShowEmail((el) => !el);
                                    copyValue('kontakt@goodwrite.pl');
                                }}
                            >
                                NAPISZ DO NAS
                            </Button>
                        ) : (
                            <Button
                                classnames="inline w-full"
                                size={'large'}
                                styleType={'secondary'}
                                type={'button'}
                                onClick={() => copyValue('kontakt@goodwrite.pl')}
                            >
                                kontakt@goodwrite.pl
                            </Button>
                        )}
                    </div>
                </BorderBox>
            </div>
            <HorizontalDivideWindow isLoggedIn={true}>
                <Gradient classnames="flex-2"></Gradient>
                <div className="flex-3"></div>
            </HorizontalDivideWindow>
        </>
    );
};

export default ManagePlan;
