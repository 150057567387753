import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import logo from 'src/assets/images/nav_logo.png';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'src/store';
import { setLoggedIn } from 'src/store/slices/authSlice';
import { useQueryClient } from 'react-query';

interface NavProps {
    isLoggedIn: boolean;
    classnames?: string;
}

const Nav: React.FC<NavProps> = ({ isLoggedIn }) => {
    const queryClient = useQueryClient();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();

    const login = () => {
        navigate('/');
    };

    const logout = () => {
        localStorage.removeItem('GW_TOKEN');
        queryClient.removeQueries('user');
        dispatch(setLoggedIn(false));
    };

    const handleLoginLogout = () => {
        if (isLoggedIn) {
            return logout();
        } else {
            return login();
        }
    };

    const toggleMenu = () => {
        setOpen((prev) => !prev);
    };

    const isLinkActive = (pathNames: string[]) => {
        return pathNames.includes(location.pathname);
    };

    const menu = (
        <>
            <Link to={'/cennik'} className="menu__item font-bold-16 text-grayscale-00 block">
                Cennik
            </Link>
            {/* {isLoggedIn && (
                <Link to={'/profil'} className="menu__item font-bold-16 text-grayscale-00">
                    Profil
                </Link>
            )} */}
            <Link
                onClick={handleLoginLogout}
                to={isLoggedIn ? '/' : '/logowanie'}
                className="menu__item font-bold-16 text-grayscale-00 block"
            >
                {!isLoggedIn ? 'Logowanie' : 'Wyloguj się'}
            </Link>
        </>
    );

    return (
        <nav>
            <div className="nav__item">
                <Link
                    className="nav__item__logo"
                    to={{ pathname: 'https://goodwrite.pl' }}
                    target="_blank"
                >
                    <img src={logo} />
                </Link>
            </div>
            {open && (
                <div className="nav__item hidden md:block absolute text-right top-[100px] right-[60px] flex flex-col">
                    {menu}
                </div>
            )}
            <div className="nav__item hidden md:block">
                <div className="hamburger">
                    <input type="checkbox" onClick={toggleMenu}></input>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <div className="nav__item block md:hidden">
                <div className="menu">{menu}</div>
            </div>
        </nav>
    );
};

export default Nav;
