import { FormikHandlers } from 'formik';
import cx from 'classnames';
import { useEffect, useState } from 'react';

interface Props {
    name: string;
    id: string;
    classnames?: string;
    type: string;
    onChange: FormikHandlers['handleChange'];
    falseText: string;
    trueText: string;
    checked: boolean;
    disabled?: boolean;
}
const SegmentedControl: React.FC<Props> = ({
    name,
    id,
    classnames,
    type,
    onChange,
    falseText,
    trueText,
    checked,
    disabled
}) => {
    return (
        <div className={cx(['segmentedControl', classnames])}>
            <input
                type={type}
                name={name}
                id={id}
                onChange={onChange}
                checked={checked}
                disabled={disabled}
            />
            <div className="segment font-semibold-16">
                <div className="slider"></div>
                <label htmlFor={id}>{falseText}</label>
                <label htmlFor={id}>{trueText}</label>
            </div>
        </div>
    );
};

export default SegmentedControl;
